import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <Layout>
    <Seo title="About the Class" />
    <div style={{ margin: "auto", maxWidth: "70ch" }}>
      <h1 style={{ padding: "1rem 1rem 0" }}>About the class</h1>
      <p>
        <StaticImage
          src="../images/image_6483441.jpg"
          loading="eager"
          width={700}
          height={394}
          quality={80}
          formats={["auto", "webp", "avif"]}
          alt="Photograph of workshop regulars with Mo Fox, together and smiling at the exhibition in June 2022."
          placeholder="blurred"
        />
      </p>
      <p style={{ padding: "0 1rem" }}>
        Artist Mo Fox has been delivering recycled art workshops to unpaid
        carers who are supported by{" "}
        <a href="https://www.signpostforcarers.org.uk/">The Signpost charity</a>{" "}
        in Stockport. Since September 2021 the participants have been creating a
        range of unique pieces of art using among other materials, map, music,
        book paper, old magazines and cardboard.
      </p>

      <p style={{ padding: "0 1rem" }}>
        An exhibition of the beautiful art took place at The Signpost building
        in Heaton Moor in June 2022 and the feedback from the show was really
        positive. A further exhibition at Stockport Art Gallery is planned for
        Winter 2022. The initiative has been funded by The National Lottery
        through The Arts Council.
      </p>
      <p style={{ padding: "0 1rem" }}>
        Mo can be contacted at{" "}
        <a href="mailto:moyafox7@gmail.com">moyafox7@gmail.com</a>.
      </p>
      <p style={{ width: "25rem", maxWidth: "67%" }}>
        <StaticImage
          src="../images/lottery-arts-council.png"
          loading="eager"
          width={700}
          quality={80}
          formats={["auto", "webp", "avif"]}
          alt="Lottery funded. Supported using public funding by Arts Council England."
          placeholder="blurred"
        />
      </p>
      <p style={{ padding: "0 1rem" }}>
        <Link to="/">Return to home page</Link>
      </p>
    </div>
  </Layout>
)

export default AboutPage
